import getGlobalStaticProps from '@lib/get-global-static-props';
import { createClient, getDocByUid } from '@lib/prismicio';
import { SliceZone } from '@prismicio/react';
import { NextSeo } from 'next-seo';
import PropTypes from 'prop-types';
import { components } from 'slices';

const Homepage = ({ meta, prismicDoc }) => {
  if (!prismicDoc) return '';
  const { title, description } = meta;
  return (
    <>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          type: 'website',
          title,
          description,
        }}
      />
      <SliceZone slices={prismicDoc?.data.slices} components={components} />
    </>
  );
};

export async function getStaticProps({ preview = false, previewData, locale }) {
  const client = createClient({ previewData });
  const globalProps = await getGlobalStaticProps(client, locale);

  const prismicDocPromise = await getDocByUid('landing_page', 'homepage', client, locale);
  const prismicDoc = await prismicDocPromise;

  const meta = {
    title: 'The Reject Shop',
    description:
      'The home of all your daily essentials. We help all Australians save money on everyday groceries, cleaning materials pet essentials and more.',
  };

  if (!prismicDoc) {
    return {
      notFound: true,
    };
  }

  // const articles = await getDocumentsByType('blog', {
  //   pageSize: 4,
  //   page: 1,
  // });

  if (!prismicDoc.data) {
    console.error('No prismic data found for page homepage');
  }

  return {
    props: {
      homepageContent: prismicDoc.data,
      globalProps,
      meta,
      prismicDoc,
      preview,
    },
    revalidate: 60,
  };
}

Homepage.propTypes = {
  globalProps: PropTypes.object.isRequired,
  prismicDoc: PropTypes.object.isRequired,
  articles: PropTypes.array.isRequired,
};

export default Homepage;
